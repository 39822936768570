<template>
  <b-row ref="updateImages">
    <b-col
      cols="12"
      md="6"
      lg="4"
      v-for='image in images' :key='image.id'
    >
      <b-img
        v-on:load="onLoaded"
        v-show="loaded"
        :src="image.url"
        fluid rounded="" alt=""
        class="img-admin"
        @click="[openImage=image,toggleModal()]"
      >
      </b-img>
    </b-col>
    <ModalDeleteImg
      :toggleModal="toggleModal"
      :openImage="openImage"
      :loadImages="loadImages"
      :images="images"
      @imageDeleted="openImage=$event"
      @reloadImages="images=$event"
      ref="imgModal"
     />
  </b-row>
</template>

<script>
import { fireStorage } from '../../plugins/firebase';
import ModalDeleteImg from './ModalDeleteImg';

export default {
  name: 'ImagesPreview',
  components: {
    ModalDeleteImg
  },
  data () {
      return {
        images: [],
        openImage: {},
        loaded: false
      }
  },
  async created() {
    await this.loadImages()
  },
  methods: {
    async loadImages() {
      const imagesRef = fireStorage.ref().child('Galerija')
      imagesRef.listAll()
        .then(res => {
          res.items.forEach(itemRef => {
            this.images.push({id: itemRef.name, url: ''})
          });
        })
        .then(async () => {
          for(let item of this.images) {
            item.url = await imagesRef.child(item.id).getDownloadURL()
          }
        })
        .catch(error => {
          console.log(error)
      });
    },
    toggleModal() {
      this.$refs.imgModal.showModal()
    },
    onLoaded() {
      this.loaded = true;
    },
    async updateImgs() {
      this.images = []
      await this.loadImages()
    }
  }
}
</script>

<style>
  .img-admin {
    height: 20vh;
    width: auto;
    margin-bottom: 10vh;
  }
  .img-admin:hover {
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 10px 4px 8px 5px rgba(0, 0, 0, 0.2), 10px 6px 20px 10px rgba(0, 0, 0, 0.19);
  }
</style>