<template>
  <div>
    <b-modal
        size="lg"
        ref="imgModal"
        hide-footer
    >
      <b-container fluid class="my-4">
        <b-row>
          <b-img fluid :src="openImage.url" class="image-in-modal mx-auto"></b-img>
        </b-row>
        <b-row><p>klikni za izbris slike</p></b-row>
        <b-row>
          <b-button @click="deleteImage" class="mx-auto" variant="success">Izbriši Sliko</b-button>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { fireStorage } from '../../plugins/firebase';
export default {
  name: 'ModalDeleteImg',
  props: {
      toggleModal: Function,
      openImage: Object,
      loadImages: Function,
      images: Array
  },
  methods: {
      showModal() {
        this.$refs.imgModal.show()
      },
      deleteImage() {
        const imagesRef = fireStorage.ref().child('Galerija')
        imagesRef.child(this.openImage.id).delete()
        .then(() => {
          this.$emit("imageDeleted", {});
          this.$emit("reloadImages", []);
        })
        .then(async () => {
          await this.loadImages()
        })
        .catch(error => {
          console.log(error)
        })
      }
  }
}
</script>

<style>
  .image-in-modal {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: auto;
    max-height: 40vh;
    margin-bottom: 50px;
    background-color: #3d3d3d;
  }
</style>