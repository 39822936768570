<template>
  <b-container class="pic-view">
      <h1>Slike</h1>
      <b-row class="my-3">
        <b-col cols="6"><b-button @click="$router.replace('/admin/')" variant="primary">Admin Stran</b-button></b-col>
        <b-col cols="6"><b-button @click="logout" variant="primary">Logout</b-button></b-col>
      </b-row>
      <b-container class="nova-slika">
        <b-row>
          <input 
            type="file"
            accept="image/*"
            @change="onFilePicked"
            style="display: none"
            required
            ref="fileInput"
          >
          <b-button raised @click="onPickFile" variant="success">Naloži Sliko</b-button>
        </b-row>
        <img v-if="showSaveImg" :src="imageURL" alt="" height="30vh">
        <b-row><b-button v-if="showSaveImg" @click="onSaveImg" variant="success">Shrani Sliko</b-button></b-row>
        <b-progress v-if="uploadStatus > 0" :value="uploadStatus" :max="max" show-progress animated></b-progress>
        <b-button v-if="uploadStatus === 100" @click="resetLoadForm" variant="success">OK</b-button>
      </b-container>
      <ImagesPreview ref="updateImages" />
  </b-container>
</template>

<script>
import { auth } from '../../plugins/firebase';
import { fireStorage } from '../../plugins/firebase';
import ImagesPreview from '../../components/admin/ImagesPreview';

export default {
  name: 'Images',
  components: {
    ImagesPreview
  },
  data () {
      return {
          image: null,
          imageURL: '',
          filename: '',
          uploadStatus: 0,
          max: 100,
          showSaveImg: false
      }
  },
  methods: {
      async logout () {
        await auth.signOut()
        this.$router.replace('/');
      },
      onPickFile () {
        this.$refs.fileInput.click()
      },
      onFilePicked (event) {
        this.showSaveImg = !this.showSaveImg
        const files = event.target.files
        this.filename = files[0].name;
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.imageURL = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.image = files[0]
      },
      async onSaveImg(){
        const storageRef= fireStorage.ref('Galerija/' + this.filename).put(this.image);
        storageRef.on(`state_changed`, snapshot=> {
            this.uploadStatus = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error=>{console.log(error.message)},
          async () => {
            this.uploadStatus=100;
            await storageRef.snapshot.ref.getDownloadURL()
              .then(async (url)=>{
                  this.imageURL =url;
              });
          }
        );
      },
      async updateImg() {
        await this.$refs.updateImages.updateImgs()
      },
      async resetLoadForm() {
        this.showSaveImg = !this.showSaveImg
        this.uploadStatus = 0
        await this.updateImg()
      }
  }
}
</script>

<style scoped>
.pic-view{
  margin: 8% auto;
}
.nova-slika{
  background-color: lightgrey;
  height: 50vh;
  margin-bottom: 10vh;
}
</style>